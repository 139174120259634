@import "../../../../../sass_modules/all.scss";

.tag {
    margin: 0 auto;
    text-overflow: ellipsis;
    font-size: 13px;
    background-color: #efffe3;
    border: 1px solid $green_01;
    border-radius: 3px;
    line-height: 13px;
    padding: 3px 2px 3px 2px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: #c9ffa0;
    }
}

.infoRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000e;
    margin-bottom: 10px;
}

.spinner {
    display: flex;
    justify-content: center;
}