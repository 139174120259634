.nav {
    max-width: 50%;
    width: 400px;

    @media(max-width:767px) {
        max-width: 100%;
        margin: 25px auto;
    }
}

.navBarGrid {
    display: flex;
    justify-content: space-around;
    align-items: center;
}