.container {
    display: flex;
    justify-content: center;
    padding: 4px 0 0 0;

    .userSelect {
        width: 300px;
        padding: 0 5px 0 5px;
    }

    .btn {
        margin: 0 5px 0 5px;
    }
}