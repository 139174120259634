.widthLimit {
  margin: 0 50px 0 50px;
}

.grid_pixels {
  display: grid;
  grid-template-columns: auto auto auto 32px 32px;
  column-gap: 5px;

  button {
    margin-top: 37px;
  }
}

.pixels_title {
  font-size: 22px;
}

.fixel_form {
  width: 100%;
}