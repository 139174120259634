@import "../../../../sass_modules/all.scss";

.header {
  display: flex;
  justify-content: space-between;
}

.balanceInfoContainer {
  display: flex;
  margin-bottom: 30px;

  .balanceInfo {
    box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
    margin-right: 5px;
    background-color: #ffffff;
    padding: 30px 30px 30px 30px;
    flex-basis: 33%;

    .balanceRecord {
      display: flex;
      justify-content: space-between;
      color: #101724;

      .balanceLabel {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .balanceLabelDesc {
        font-size: 12px;
        line-height: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .balanceValue {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
      }
    }

    .payoutButton {
      width: 95%;
      height: 36px;
      font-size: 17px;
      background-color: $green_01;
      color: white;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      margin: 20px 0;
    }

    .pendingTransactionsConatainer {
      .pendingTransactionRecord {
        display: flex;
        justify-content: flex-end;

        .pendingTransactionValues {
          font-weight: bold;
          font-size: 16px;
        }

        .pendingTransactionValuesRed {
          @extend .pendingTransactionValues;
          color: #ff6655;
        }

        .pendingTransactionValuesGreen {
          @extend .pendingTransactionValues;
          color: $green_01;
        }
      }
    }
  }

  .balanceGraph {
    flex-basis: 67%;
    margin-left: 5px;
  }
}

.transactionTypeControls {
  display: flex;
  margin: 28px auto 0 auto;
  
  .transactionTypeLabel {
    display: flex;
    text-align: center;
    margin: 0px 0px 0px 10px;
    padding: 0px 0px 5px 0px;
    font-weight: bold;
    cursor: pointer;
  }

  .transactionTypeLabelSelected {
    @extend .transactionTypeLabel;
    border-bottom: 1px solid $green_01;
    color: $green_01;
  }
}

.filterButton {
  background-color: $green_01;
  border-color: $green_01;
  border-radius: 0px;
}