$green_01: #22B74B;
$green_02: #1CCA4D;
$grey_01: #959595;
$grey_02: #7c7c7c;
$grey_03: #4d4d4d;
$shadow: #0d224d;
$bg: #1e253b;
$blue01: #2c3657;
$text: #101724;
$white: #ffffff;
$bg_white: #f8fafc;

$cc_green: #24A88C;