.grid_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
  font-size: 16px;
  padding: 10px;
  border-bottom: gray 1px solid;
}

.grid_col_2_ligth {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}

.grid_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  padding: 15px;
}

.listItemStyle1 {
  background-color: #e1eaea;
  &:hover {
    border-color: #22b748 !important;
    background-color: #99ff99;
  }
}

.listItemStyle2 {
  background-color: #c3d5d5;
  &:hover {
    border-color: #22b748 !important;
    background-color: #99ff99;
  }
}