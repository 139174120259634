@import "../../../../../sass_modules/all.scss";

.grid {
  margin-top: 50px;

  .header {
    color: $text;
    margin-bottom: 23px;
  }

  .tableWidth {
    width: 100%;
  }
}

.button {
  background: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    color: $green_01;
  }
}

.editButton {
  margin-right: 10px;
}

.deleteButton {
  &:hover {
    color: red;
  }
}
