@import "../../../../../../sass_modules/all.scss";


.container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .changeToStatusContainer {
        margin: 0% 2% 0% 1%;
    }

    .buttonRow {
      width: 100%;
      display: flex;
    }
    
    .commonButton {
      width: 100%;
      border: 1px solid $green_01;
      background: transparent;
      color: $green_01;
      transition: all 0.3s ease-in-out;
      border-radius: 0px;
      padding: 0 5px 0 5px;
    
      &:hover {
        background: $green_01;
        color: $white;
        border-color: $green_01 !important;
      }
    
      &:disabled {
        cursor: not-allowed;
        background: #e9e9e9;
        color: $green_01;

      }
    }

    
    .button {
        @extend .commonButton;
        margin: 1%;
    }
    
    .activeButton {
        @extend .button;
        background: $green_01;
        color: $white;
    }

    .link {
      margin: 1%;
      width: 100%;
    }

}

