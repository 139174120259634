.add-filters {
  margin-right: 20px;

  &__text {
    cursor: pointer;

    color: #009eed;
    font-size: 14px;
    line-height: 22px;
  }
}
