
.label {
    padding: 5px;
    text-transform: uppercase;
    font-weight: 900;
    color: #ffffff;
    padding: 2px;
    border-radius: 2px;
    margin: 0 10% 5% 10%;
    text-align: center;
    font-size: 12px;
}

.redLabel {
    @extend .label;
    background-color: rgb(255, 140, 140);
    border: 1px solid rgb(180, 0, 0);
}

.greenLabel {
    @extend .label;
    background-color: #22b74b;
    border: 1px solid #1a8b3a;
}