@import '../../../sass_modules/all.scss';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    max-width: 100%;
    padding: 10px 15px;
    margin: 0;
    box-shadow: none;
    transition: all .3s linear;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.button__green {
    border: 1px solid $cc_green;
    color: #fff;
    background: $cc_green;
    &:hover {
        color: $cc_green;
        background: #fff;
        border: 1px solid $cc_green;
    }
}

.button__white {
    border: 1px solid $cc_green;
    color: $cc_green;
    background: #fff;
    &:hover {
        color: #fff;
        background: $cc_green;
    }
}

.button__disabled {
    border: 1px solid $cc_green;
    color: $cc_green;
    background: #ddd;
    &:hover {
        cursor: not-allowed;
    }
}