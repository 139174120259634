@import "../../../../../sass_modules/all.scss";

.grid {
  margin-top: 30px;
  max-width: 337px;
  width: 100%;

  .formItem {
    width: 100%;

    input,
    textarea {
      width: 100%;
      outline: none;
      box-shadow: none;
      border: 1px solid $grey_02;
      border-radius: 0px;
      height: 36px;

      &:focus,
      &:hover {
        border: 1px solid rgba(34, 183, 72, 1) !important;
      }
    }

    label {
      text-align: left;
      width: 100%;
      color: $grey_02;
    }
  }

  .formFooter {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 184px 133px;
    grid-column-gap: 20px;

    .controls {
      width: 100%;
      height: 36px;
      font-size: 14px;
      line-height: 18px;
      border: 1px solid $green_01;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.9;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .submit {
      @extend .controls;
      background: $green_01;
      color: $white;
      border: 1px solid black;
      border-radius: unset;

      &:hover {
        background: $green_02;
        font-size: 105%;
      }
    }

    .reset {
      @extend .controls;
      background: transparent;
      color: $green_01;
      border: 1px solid $green_01;
      border-radius: unset;

      &:hover {
        font-size: 105%;
      }
    }

    button:disabled,
    button[disabled] {
      cursor: not-allowed;
    }
  }
}
