.container {
    display: grid;
    grid-template-columns:  60px auto;
    grid-gap: 15px;
    margin-bottom: 30px;

    img {
        display: block;
        margin: 0;
    }
}

.texts {
    padding-top: 10px;
    .h3 {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #101724;
        margin-bottom: 10px;
    }

    .p {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #7c7c7c;
        margin: 0;
    }
}