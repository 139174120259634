.ant-collapse {
    background: inherit !important;
    border: none !important;
}

.ant-collapse-item {
    margin-bottom: 24px;
}

.ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
    background: #fff;
    border-radius: 0 !important;
    border: 1px solid black !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.ant-collapse-content {
    font-size: 16px;
    color: #000 !important;
    font-weight: normal;
    line-height: 22px;
    border-radius: 0 !important;
    border: 1px solid black !important;

}

.ant-collapse-item-active {
    .ant-collapse-header {
        border-bottom: none !important;
    }

    .ant-collapse-content {
        border-top: none !important;
    }
}