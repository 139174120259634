.ant-list-pagination{
    text-align: center;
}

.listItemContainer{
    /* width: 439px; */
    height: 144px;
    background-color: #FFFFFF;
}

.itemTitle{
    font-size: 16px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 6px;
}

.itemContent{
    font-size: 15px;
    margin-top: 10px;
}

.itemLabel{
    padding: 6px 10px;
    font-size: 16px;
    text-align: center;
}

.important{
    background-color: #FBBC06;
}

.new{
    background-color: #29C05C;
    color: #FFFFFF;
}
.info{
    background-color: #2C50ED;
    color: #FFFFFF;
}


