@import '../../../sass_modules/all.scss';


.customCheckbox {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;

    input[type='checkbox'] {
        width: 20px;
        height: 20px;
    }

    input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    .overlay {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 20px;
        width: 20px;
        background-color: $white;
        border: 1px solid $grey_01;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            color: white;
            display: none;
        }
    }

    input:checked~.overlay {
        background-color: $green_01;
        opacity: 1;
        border: none;
    }

    input:checked~.overlay .icon {
        display: block;
    }
}