.item {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1350px) {
    margin-bottom: 0;

    &:first-child {
      margin-right: 30px;
    }
  }

  @media (max-width: 650px) {
    margin-bottom: 20px;

    &:first-child {
      margin-right: 0;
    }
  }
}

.icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 20px;

  border-radius: 50%;
  background-color: #e0f3ff;
}

.statistic {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.rate {
  &__value {
    font-size: 24px;
    font-weight: 700;

    margin-right: 2px;
  }

  &__percentage {
    font-size: 14px;
  }
}
