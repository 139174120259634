@import '../../../../../sass_modules/colors.scss';
.grid {
    display: flex;
    align-items: center;
    width: 100%;

    p {
        color: $white;
        margin: 0!important;
    }

    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}