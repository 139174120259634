.tools {
  margin: 40px 0px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;

  @media (min-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
