.grid {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  min-height: 100vh;
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submit {
  background-color: #22b748;
  width: 400px;
  height: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  margin: 20px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: #fff;
    background-color: #24c442;
  }
}
