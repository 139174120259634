.checked {
    background: rgba(36, 168, 140, 0.1);
}

.listElement {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.leftCol {
    width: 80%;
}

.rightCol {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.value {
    font-weight: bold;
    color: black;
}

.checkBox {
    margin-right: -10px;
    width: 18px;
    height: 18px;
    border: 1px solid #898c90;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkBox__dot {
    width:10px;
    height: 10px;
    border-radius: 50%;
    background: #24a88c;
    transition: opacity .1s ease;
}

