.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .row {
        padding: 10px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}