.header {
  display: grid;
  grid-template-columns: 1fr 5fr 8fr 9fr 8fr 5fr 14fr;
  padding: 10px 20px;
  background-color: #22b74b
}

.grid_col_6 {
  display: grid;
  grid-template-columns: 1fr 5fr 8fr 9fr 8fr 5fr 14fr;
  padding: 10px 10px 0 10px;
}

.grid_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.grid_col_9 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  column-gap: 10px;
  margin: 10px 8%;
  text-align: center;
}

.label {
  color: white;
  text-align: left;
}

.detailsBox {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
}

.listItem {
  background-color: #ffffff;
  padding: 10px;

  &:hover {
    border-color: #f5f5f5 !important;
    background-color: #f5f5f5;
  }
}
