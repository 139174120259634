.statistics {
  padding: 35px 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 1625px) {
    padding: 25px 30px;
  }

  @media (max-width: 1350px) {
    flex-direction: column;
  }
}

.approve {
  flex-basis: 300px;
  padding-right: 50px;

  border-right: 2px solid #f2f2f4;

  @media (max-width: 1350px) {
    display: flex;
    flex-basis: auto;
    padding-right: 0;
    margin-bottom: 30px;

    border-right: none;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.main-statistic {
  width: 100%;
  padding: 7px 30px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  @media (max-width: 1625px) {
    margin-bottom: -20px;
  }

  @media (max-width: 1350px) {
    padding: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.classifiers {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 50px;
  flex-basis: 250px;
  display: flex;
  flex-direction: column;

  position: relative;

  border-left: 2px solid #f2f2f4;

  @media (max-width: 1350px) {
    border-left: none;
    padding-left: 0;
    flex-basis: auto;
    flex-direction: row;
  }

  &__title {
    position: absolute;
    top: -15px;

    color: #bdbaba;
    font-weight: 700;
    text-transform: uppercase;
  }
}
