@import "../../../../sass_modules/all.scss";

.spiner {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  margin: 20px 0 0 0;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
    }

    .addButton {
      width: 133px;
      border: 1px solid $green_01;
      background: transparent;
      color: $green_01;
      transition: all 0.3s ease-in-out;
      border-radius: 0px 0px 0px 0px;

      &:hover,
      &:focus {
        background: $green_01;
        color: $white;
      }
    }
  }

  .postbackSettingsForm {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .sectionDivider {
    height: 1px;
    width: 100%;
    background-color: #e8e8e8;
    margin-bottom: 30px;
  }
}
