.paragraph {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  max-width: 480px;
  width: 100%;
  text-align: center;
  margin: 30px auto;
  padding: 0 10px;
}

.submit {
  background-color: #22b748;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  margin: 20px auto 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    color: #fff;
  }
}
