.statistic {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: white;

  &__digit {
    font-size: 24px;
    color: #333;
    min-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__descr {
    background: #eee;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      position: absolute;
      top: 3px;
      right: -10px;

      &:hover {
        color: #1ecf53;
        font-size: 110%;
      }
    }

    h4 {
      position: relative;
      font-weight: bold;
      font-size: 14px;
      margin: 0;
    }
  }
}

.tooltip_style {
  text-align: center;
}

.low_status {
  color: #e43933 !important;
}

.middle_status {
  color: #f39c10 !important;
}

.high_status {
  color: #00a65a !important;
}

//TODO: Filtrowanie po statystykach
.showHover {
  // &:hover {
  //   cursor: pointer;
  //   border: 1px green solid;
  //   -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.81);
  //   box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.81);
  //   font-size: 26px;

  //   h4 {
  //     font-weight: bold;
  //     font-size: 16px;
  //     margin: 0;
  //   }

  //   p {
  //     font-size: 14px;
  //     margin: 0;
  //   }

  //   div {
  //     font-size: 26px;
  //   }
  // }
}

.showError {
  cursor: pointer;
  border: 1px red solid;
  font-size: 24px;

  div {
    background-color: red;
    font-size: 24px;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.81);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.81);

    h4 {
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    div {
      background-color: red;
      font-size: 28px;
    }
  }
}
