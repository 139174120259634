.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 730px) {
    align-items: flex-start;
  }
}

.filters {
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;

    & > * {
      margin-bottom: 5px;
    }
  }
}

.indicators {
  display: flex;
  margin-left: 135px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    margin-left: 0;
  }
}
