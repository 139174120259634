
.grid {
    display: flex;
    align-items: center;
}

.text {
    margin: 0 13px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    width: 10px;
    margin-left: -9px;

    i {
        margin-right: 0!important;
    }
}