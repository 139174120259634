.item {
  display: flex;
  align-items: flex-start;

  @media (max-width: 1625px) {
    margin-bottom: 20px;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.statistic {
  &__title {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__value {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-bottom: 0;
  }
}
