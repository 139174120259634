.grid_col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
}

.grid_col_6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
}