.customAnt {
    .ant-anchor-ink {
        display: none;
    }

    .ant-anchor-wrapper {
        background: transparent;
        padding: 0;
        margin: 0;
    }

    .ant-anchor-link-title {
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        font-weight: normal;
    }
}