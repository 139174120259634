.header {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

.form {
    width: 100%;
}

.formFlex {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
}

.item {
    width: 33.33%;
    label {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #D8D3D3;
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
    }

    .tbLeftRadius {
        border-radius: 4px 0 0 4px;
    }

    .tbRightRadius {
        border-radius: 0 4px 4px 0;
    }
}

.errorMessage {
    color: red;
    margin: 10px auto;
}