.container {
    max-width: 630px;
    margin: 50px auto;
    
    .rowElements {
        display: flex;
    
        .selectContainer {
            padding-right: 30px;
        }
    }
}
