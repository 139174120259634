@import '../../../../../../../sass_modules/all.scss';

.card {
    background: $white;
    width: 100%;
    height: 107px;
    box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
    padding: 30px;
    margin-bottom: 20px;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .h6 {
            color: $text;
            margin:0;
        }

        .date {
            color: $grey_02;
            margin: 0;
        }
    }

    .body {
        .para {
            color: $grey_03;
            margin: 0;
        }
    }
}