.sidebar-title {
  padding: 8.6px 0;
  padding-left: 7px;
  background-color: #e8e8e8;

  &__text {
    font-weight: 700;
  }

  &__icon {
    margin-right: 5px;
  }
}
