.buttonStyle {
  margin-left: 5px;
  width: 250px;
  font-weight: bold;
}

.formStyle {
  max-width: 1550px;
  margin-left: auto;
  margin-right: auto;
}

.checkBoxStyles {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}