.grid {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Lato", "Arial";

  h5 {
    font-family: "Open Sans", "Arial";
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000;
    margin: 20px auto 10px;
    text-align: center;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    color: #101724;
    margin: 0;
  }

  .btn {
    width: 280px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #22b74b;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    margin: 30px auto;
  }

  .sessionExpired {
    color: red;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .sessionExpiredDescription {
    width: 300px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.icon {
  color: red;
  font-size: 50px;
}
