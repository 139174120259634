.contactForm {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
  width: 100%;

  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }

  .formItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 18px;

    &__input,
    &__textarea {
      width: 100%;
      outline: none;
      box-shadow: none;
      border: 1px solid #959595;
      height: 40px;
      font-size: 15px;
      line-height: 18px;
      color: #101724;

      &:focus {
        border: 1px solid rgba(34, 183, 72, 1);
      }
    }

    &__label {
      text-align: left;
      width: 100%;
      font-size: 15px;
      line-height: 18px;
      color: #101724;
      margin-bottom: 8px;
    }

    &__input {
      padding-left: 15px;
    }

    &__textarea {
      height: 120px;
      resize: none;
      margin-bottom: 30px;
      padding: 10px 15px;
    }

    &__submit {
      width: 230px;
      height: 50px;
      border: 2px solid #22b748;
      background: transparent;
      color: #22b748;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      align-self: flex-end;

      &:hover {
        opacity: 0.8;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      @media (max-width: 767px) {
        width: 90%;
        align-self: center;
      }
    }
  }
}
