@import "./Variables.scss";

.form-control-column-label {
    text-align: right;
    padding-right: 5px !important;
    line-height: 0.9;
    margin: 20px 0px 5px 3px;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.form-control-column-input {
    min-height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.label-control {
    color: rgba(0, 0, 0, .85);
}

.ant-col>span {
    width: 100% !important;
}

.label-text-align-left {
    width: 100%;
    text-align: left;
}

.small-height-form-control-column-label {
    @extend .form-control-column-label;
    height: 15px;
}

.input-without-borders {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.font-weight-bold {
    font-weight: bold;
}

.wrapper {
    width: 100%;
}

.wrapper .ant-calendar-picker {
    width: 100%;
}