.filterButton {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
  box-shadow: unset;
  text-shadow: unset;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    color: #5fe21d;
    background: #f6ffed;
    border-color: #c7f0a8;
  }
}

.filterButtonOrderCreated {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
  box-shadow: unset;
  text-shadow: unset;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    color: #f273b9;
    background: #fff0f6;
    border-color: #ffcce3;
  }
}

.leads_container_style {
  margin: 25px 25px 10px 25px;
}

.affix_styles {
  width: 100%;
  height: 42px;
  margin: unset;
  background-color: gainsboro;
}

.refresh_table {
  margin: 5px 10px 0px 25px;
}

.filters_button {
  margin: 5px 0px 0px 0px;
}

.found_rows_number {
  margin: 12px 25px 0px 0px;
}

.errorsContainer {
  width: 100%;
  margin-top: 20px;
  max-height: 500px;
  border: 1px solid #00000025;
  overflow-x: auto;

  .row {
    display: flex;
  }

  .headerRow {
    @extend .row;
    border-bottom: 1px solid #00000025;
  }

  .errorRow {
    @extend .row;
    background-color: #fc85856c;
  }

  .warningRow {
    @extend .row;
    background-color: #fce0856c;
  }

  .cell {
    padding: 5px 15px 5px 15px;
  }

  .leadIdContainer {
    @extend .cell;
    flex-basis: 20%;
  }

  .messageContainer {
    @extend .cell;
    flex-basis: 80%;
  }
}
