.grid {
    margin: 30px auto;
    width: 141px;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(#959595, .2);
    display: flex;
    justify-content: space-between;
}

.navItem {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #101724;

    &:hover {
        color: #101724;
    }
}

.navItem__active {
    color: #22b74b;
    position: relative;

    &:hover {
        color: #22b74b;
    }

    &::after {
        content: '';
        position: absolute;
        width: 35px;
        height: 1px;
        left: 0;
        bottom: -6px;
        transform: translateY(100%);
        background-color: #22b74b;
    }
}