@import '../../../../../../sass_modules/all.scss';

.container {
  display: flex;
  align-items: center;

  .label {
    align-content: center;
  }

  .button {
    margin-left: 0.5vw;
    border: 1px solid $green_01;
    background: transparent;
    color: $green_01;
    transition: all 0.3s ease-in-out;
    border-radius: 0px;

    &:hover {
      background: $green_01;
      color: $white;
      border-color: $green_01 !important;
    }
  }
}
