@import "../../../../../sass_modules/all.scss";

.grid {
  padding: 10px;

  button {
    box-shadow: none;
    border: none;
    outline: none;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    height: 36px;

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }

  .row {
    margin-bottom: 20px;
  }

  .dividerLine {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
  }

  .firstRowGrid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .secondRowGrid {
    .secondRowHeaders {
      display: grid;
      grid-template-columns: 184px 184px;
    }

    label {
      display: block;
      margin-bottom: 10px;
    }

    &__form {
      display: grid;
      grid-template-columns: 451px 133px;
      grid-gap: 20px;
      grid-template-areas: "input addNewBtn";
    }

    .inputs {
      grid-area: input;
      display: grid;
      width: 388px;
      row-gap: 20px;

      .inputsGrid {
        display: grid;
        grid-template-columns: 203px 233px;
        align-items: center;
      }

      .paramValue {
        width: 168px;
      }
    }

    .addNewBtn {
      grid-area: addNewBtn;
      width: 133px;
    }
  }

  .thirdRowGrid {
    margin-bottom: 0;
  }

  .btnContainer {
    display: flex;
    justify-content: space-between;
  }

  .submitBtn {
    width: 184px;
    color: $white;
    font-family: "Lato", "Arial";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-top: 30px;
  }

  .changeModeBtn {
    width: 184px;
    color: $white;
    font-family: "Lato", "Arial";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-top: 30px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }

  .gray {
    background: $grey_01;
  }

  .green {
    background: $green_01;
  }

  .addNewBtn {
    width: 133px;
    border: 1px solid $green_01;
    background: transparent;
    color: $green_01;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      background: $green_01;
      color: $white;
    }
  }

  .fakeFormItem {
    .label {
      margin-bottom: 10px;
      color: $grey_03;
    }
  }

  .fakeInput {
    // width: 388px;
    height: 36px;
    padding-left: 5px;
    background: #e6e6e6;
    border: 1px solid $grey_01;
    display: flex;
    align-items: center;
  }

  .formItem {
    label {
      display: block;
      margin-bottom: 10px;
      color: $grey_03;
    }

    input {
      height: 36px;
      background: $white;
      border: 1px solid $grey_01;
      box-shadow: none;
      outline: none;
      padding-left: 12px;
      border-radius: 0px 0px 0px 0px;

      &:focus,
      &:hover {
        border: 1px solid $green_01;
      }
    }
  }

  .customSelect {
    input {
      background: $white;
    }
  }

  .deleteBtn {
    height: 36px;
    width: 36px;
    background: $green_01;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    outline: none;
    color: #e6e6e6;
    border-radius: 0px 0px 0px 0px;

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }

  .formItemDelete {
    width: 204px !important;
    display: flex;
    align-items: center;
  }

  .customError {
    color: red;
    margin-top: 10px;
  }
}
