.maxWidth1200 {
    max-width: 1200px;
    margin: 0 auto;
}

.landingPageContainer {
    // font-family: 'Nunito Sans', sans-serif;
    background: #1e253b;
}

.headerGrid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    color: #fff;

    @media(max-width: 767px) {
        flex-direction: column;
    }
}

.headerLangLoginBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;

    a {
        color: #1CCA4D;
        position: relative;

        &::after {
            content: '>';
            position: absolute;
            top: 0;
            right: -7px;
            transform: translateX(100%);
        }
    }
}

.drawerOpener {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    margin: 0;
    margin-left: 50px;
    cursor: pointer;
    position: relative;
    color: #1CCA4D;
    font-size: 14px;
    transition: color .3s ease-in-out;

    &::after {
        content: '>';
        position: absolute;
        top: 0;
        right: -8px;
        transform: translateX(100%);
    }

    &:hover {
        color: #fff;
    }
}

.s_hero {
    padding-top: 90px;
    padding-bottom: 300px;
    position: relative;

    @media(max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px;
        overflow: hidden;
    }

    .waveBg {
        position: absolute;
        max-width: 100%;
        bottom: 0px;
        left: 0;
        z-index: 2;

        @media(max-width: 767px) {
            max-width: none;
            left: -800px;
            bottom: 50px;
        }

    }

    .screen {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 630px;
        height: 440px;
        box-shadow: 20px 20px 40px rgba(13, 34, 66, 0.15);
        background: white;
        // border: 6px solid #1e253b;
        border-radius: 25px;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }

        @media(max-width: 767px) {
            display: none;
        }
    }

    h3 {
        color: #fff;
        // font-family: 'Nunito Sans', sans-serif;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 10px;
    }

    h2 {
        color: #fff;
        // font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
    }

    .statsGrid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        margin: 80px auto 0;

        @media(max-width: 767px) {
            padding: 0 20px;
            margin: 108px auto 0;
        }

        &__item {
            width: 140px;
            text-align: center;

            h4 {
                font-size: 30px;
                line-height: 36px;
                // font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                color: #fff;
                margin-bottom: 5px;
            }

            p {
                // font-family: 'Nunito Sans', sans-serif;
                color: #fff;
                font-size: 15px;
                line-height: 18px;
                margin: 0;
            }
        }
    }
}

.s_about {
    background: #f8fafc;
    padding-top: 330px;
    padding-bottom: 120px;

    @media(max-width: 767px) {
        padding: 50px 20px;
        position: relative;
        overflow: hidden;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media(max-width: 767px) {
            grid-template-columns: 1fr;
            grid-gap: 30px;
        }

        h3 {
            // font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
            margin-bottom: 25px;
            color: #101724;
            z-index: 2;
            position: relative;
        }

        p {
            position: relative;
            font-size: 15px;
            line-height: 20px;
            margin: 0;
            color: #101724;
            max-width: 530px;
            z-index: 2;
        }
    }

    &__left {
        position: relative;

        &__squareDots {
            position: absolute;
            bottom: 0;
            right: 15%;
            z-index: 1;

            &__mobile {
                display: none;
            }
        }

        &__rectangleDots {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateX(-50%);
            z-index: 1;

            &__mobile {
                display: none;
            }
        }

        @media(max-width: 767px) {
            &__squareDots {
                display: none;

                &__mobile {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translate(-50%, 50%);
                }
            }

            &__rectangleDots {
                display: none;

                &__mobile {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(80%, -85%);
                }
            }
        }
    }
}

.s_topOffers {
    padding-top: 120px;
    padding-bottom: 178px;
    overflow: hidden;

    @media(max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 80px;
    }

    h3 {
        color: #fff;
        // font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
    }

    .sliderContainer {
        max-width: 1200px;
        margin: auto;
        margin-top: 40px;
        position: relative;

        &__squareDots {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-50%, 50%);
        }

        &__rectangleDots {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
        }
    }

    .noOffers {
        p {
            color: #fff;
            font-size: 18px;
            text-align: center;
            margin-top: 100px;
        }
    }
}

.sliderItem {
    display: flex;
    justify-content: center;
}

.s_contact {
    background: #fff;

    img {
        max-width: 100%;
        height: 100%;
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media(max-width:767px) {
            grid-template-columns: 1fr;

            &__left {
                display: none;
            }
        }

        &__right {
            padding: 120px 20px 120px;

            @media(max-width:767px) {
                padding: 40px 30px;
                text-align: center;
            }

            h3 {
                font-family: 'Open Sans';
                font-size: 26px;
                line-height: 30px;
                color: #101724;
                margin-bottom: 25px;
            }

            p {
                font-size: 15px;
                line-height: 20px;
                color: #101724;
                margin: 0;
            }
        }
    }
}

.s_becomeAPartner {
    padding: 52px 20px;

    h3 {
        color: #fff;
        font-size: 18px;
        line-height: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }
}