.img {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
}

.children {
    text-align: center;

    h6 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        font-family: 'Open Sans', 'Arial';
        margin-bottom: 11px;
    }

    p {
        font-family: 'Lato', 'Arial';
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
    }

}