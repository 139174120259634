@import "../../../../sass_modules/all.scss";

.dashboardGrid {
  margin-top: 40px;
}

.topOffersGrid {
  margin-top: 50px;
}

.newsGrid {
  margin-top: 50px;
}

.button__allNews {
  width: 184px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $green_01;
  font-family: "Lato", "Arial";
  font-size: 14px;
  border: 2px solid $green_01;
  margin: 30px auto;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $white;
    background: $green_01;
  }

  &:focus {
    outline: none;
  }
}
