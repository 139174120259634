.grid_col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
}

.grid_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
}

.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #40a9ff;
  margin-top: 15px;
}

.form_style {
  background-color: #f9f9f9;
  padding: 20px;
  width: 100%;
}