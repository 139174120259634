@import '../../../../sass_modules/all.scss';

.contactForm {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 337px;
    width: 100%;

    .nomargin {
        margin-bottom: 0!important;
    }

    .formItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16px;

        &__input,
        &__textarea {
            width: 100%;
            outline: none;
            box-shadow: none;
            border: 1px solid $grey_02;
            height: 36px;
            font-size: 15px;
            line-height: 18px;

            &:focus {
                border: 1px solid rgba(34, 183, 72, 1)
            }
        }

        &__label {
            text-align: left;
            width: 100%;
            color: $grey_02;
            margin-bottom: 8px;
        }

        &__input {
            padding-left: 15px;
        }

        &__textarea {
            height: 130px;
            resize: none;
            margin-bottom: 30px;
            padding: 10px 15px;
        }

        &__submit {
            width: 184px;
            height: 36px;
            background: $green_01;
            color: $white;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            transition: opacity .3s ease-in-out;
            align-self: flex-start;

            &:hover {
                opacity: .9;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}