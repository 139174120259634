.label {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.cartSummary {
    padding-top: 15px;
    width: 85%;
    padding-right: 20px;
}

.cartSummary__totalRow {
    display: flex;
    justify-content: space-between;
    p {
        font-weight: bold;
        color: black;
    }
}

.cartSummary__discountRow {
    display: flex;
    justify-content: space-between;

    p:first-child {
        color: #6a6c70;
    }

    p:last-child {
        color: #24a88c;
    }
}