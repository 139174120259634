.grid {
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;

    form {
        width: 100%;
    }

    .formItem {
        color: #101724 !important;
        font-size: 15px !important;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 8px;
            line-height: 18px;
        }

        input {
            width: 100%;
            height: 40px;
            border: 1px solid #959595;
            padding: 0 !important;
            padding-left: 15px !important;
            background: #fff;
            border-radius: 0;
            outline: none !important;

            &:focus {
                border-color: #22b748 !important;
                box-shadow: none !important;
            }

            &:hover {
                border-color: #22b748 !important;
            }
        }
    }

    .submit {
        background-color: #22b748;
        width: 100%;
        height: 50px;
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        margin: 14px auto 10px;
        cursor: pointer;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}