@import "../../../../../sass_modules/all.scss";


.formItem__outsideForm {
  margin-bottom: 16px;

  .label {
    color: $grey_02;
    margin-bottom: 3px;
  }

  .fakeInput {
    margin-bottom: 10px;
    
    p {
      padding: 0 5px 0 5px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      min-height: 36px;
      background: #e6e6e6;
      margin: 0 0 10px 0;
      border: 1px solid $grey_01;
      color: $grey_03;
    }

    .controlsContainer {
      display: flex;
      justify-content: flex-end;
      height: 100%;

      .controlButton {
        min-height: 36px;
        min-width: 36px;
        background: $green_01;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        outline: none;
        color: #e6e6e6;
        border-radius: 0px 0px 0px 0px;
        margin-left: 5px;

        &:focus,
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
