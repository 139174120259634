.incorrect_amount_button {
  background-color: #e80004;
  color: black;
  margin-bottom: 10px;
  width: 100%;

  &:hover {
    color: BLACK;
    border-color: BLACK;
    background-color: #ff0000;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  }

  &:focus {
    color: BLACK;
    border-color: BLACK;
    background-color: #ff0000;
  }
}
