.header {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

.form {
    width: 100%;
}

.errorMessage {
    color: red;
    margin: 10px auto;
}

.label {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #D8D3D3;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.label2 {
    display: flex;
    font-weight: bold;
    margin-bottom: 5px;
}

.item50 {
    width: 33%;
}

.wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.item33 {
    width: 33.33%;
}

.tbLeftRadius {
    border-radius: 4px 0 0 4px;
}

.tbRightRadius {
    border-radius: 0 4px 4px 0;
}