@import '../../../../../sass_modules/all.scss';


.grid {

    h3 {
        margin-bottom: 20px;
    }
    .formGrid {

        display: grid;
        grid-template-columns: repeat(3, 110px);
        grid-column-gap: 76px;
        margin-bottom: 30px;

        section>h5 {
            color: $text;
            margin-bottom: 16px;
        }

        .formItem {
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .formItem>label:last-child {
            margin: 0;
            margin-left: 10px;
            color: $text;
        }
    }

    .submit, .reset {
        outline: none;
        box-shadow: none;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease-in-out;
        cursor: pointer;
        height: 36px;
    }

    .submit {
        color: $white;
        background: $green_01;
        border: none;
        width: 184px;

        &:hover {
            opacity: .8;
        }
    }

    .reset {
        color: $green_01;
        background: transparent;
        border: 1px solid $green_01;
        width: 120px;
        margin-left: 10px;

        &:hover {
            opacity: .7;
        }
    }

    form>section:last-child {
        display: flex;
        align-items: center;
    }

    .originalCountryLabel {
        color: red !important;
    }
}