@import "../../../../sass_modules/all.scss";

.offersList {
  margin: 0 auto;
  max-width: 898px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;

    p {
      margin: 0;
    }
  }

  .filtersGrid {
    display: flex;
    align-items: center;
  }

  .globalSearchInput {
    flex: 1;
    height: 40px;
    width: 100%;
    outline: none;
    box-shadow: none;
    border: 1px solid #7c7c7c;
    padding-left: 5px;
    margin-right: 16px;

    &:focus {
      border: 1px solid #22b748;
    }
  }

  .showGlobalSearch {
    height: 40px;
    width: 40px;
    border: 1px solid #22b748;
    padding: 0;
    margin: 0;
    margin-right: 16px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  .counterGrid {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .counter {
    font-family: "Nunito Sans", sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $text;
    margin-bottom: 0;
  }

  .radioGrid {
    display: flex;
    align-items: center;
    // border: 1px solid purple;
  }

  .radioGrid > label {
    height: 19px;
    margin-bottom: -2px;
    // border: 1px solid green;
  }

  .listGrid {
    max-width: 898px;
    margin: 0 auto;
  }

  .sortByIcon {
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
    background: $green_01;
    cursor: pointer;
    outline: none;
    transition: opacity 0.3s ease-in-out;

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }
}
