.col_4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.sectionDivider {
    width: 100%;
    height: 1px;
    background-color: #40a9ff;
    margin: 25px auto;
}