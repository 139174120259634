.open-popover {
  margin-right: 10px;
  display: flex;
  align-items: center;

  cursor: pointer;

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__title {
    margin-right: 5px;

    color: #339fdd;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
  }

  &__trigger {
    svg {
      color: #339fdd;
      font-size: 12px;
    }
  }
}

.columns-list {
  margin-top: 10px;
  padding-left: 10px;

  list-style: none;

  &__item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
