.container {
  display: flex;
  flex-wrap: wrap;

  .title {
    padding: 5px 8px;
    font-size: 15px;
    font-weight: bold;
}

  .filter {
    margin: 0 10px 10px 0;
    padding: 5px 8px;
    border: 1px solid #0e82fa;
    color: #0e82fa;
  }
}
