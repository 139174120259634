.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    p {
        margin-bottom: 0;
    }
}

