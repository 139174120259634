.grid_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}

.grid_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
}

.companieName {
  text-align: center;
  font-weight: bold;
  color: #004d00;
  font-size: 16px;
}

.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #40a9ff;
  margin-top: 15px;
}
