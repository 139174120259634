.footer {
    background: #101724;
    padding: 30px 10px;

    .widthLimit1200 {
        max-width: 1200px;
        margin: 0 auto;
    }

    &__grid {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        div {
            width: 320px;
        }

        &__right {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    p, a {
        margin: 10px auto 0;
        color: #959595;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 14px;
        line-height: 17px;
    }

    @media(max-width: 767px) {
        .logo {
            display: none;
        }

        .footer__grid {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                text-align: center;
                margin: 25px auto;
            }
        }
    }
}