.grid_col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
}

.userName {
  text-align: center;
  font-weight: bold;
  color: #004d00;
  font-size: 16px;
}

.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #40a9ff;
  margin: 25px auto;
}

.label {
  display: block;
  text-align: left !important;
  padding-right: 5px !important;
  line-height: 0.9;
  color: black;
  font-weight: bold;
  height: 40px;
  display: flex !important;
  align-items: center !important;
}
