@import '../../../../sass_modules/all.scss';

.arrowHeader {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &__active {
        color: $green_01;

        & .arrowHeader__arrow {
            background: $green_01;

            &::before, &::after {
                background: $green_01;
            }
        }
    }
}

.arrowHeader__text {
    width:  100%;
    color: inherit;
}

.arrowHeader__arrow {
    display: block;
    margin-left: 5px;
    height: 1px;
    width: 100%;
    background: #C4CDDA;
    position: relative;

    &::before, &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 1px;
        background: #C4CDDA;
        right: 0;
        transform-origin: center right;
    }

    &::before {
        transform: rotate(30deg)
    }

    &::after {
        transform: rotate(-30deg)
    }
}