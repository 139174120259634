.statistics {
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.unrecognized {
  background-color: red;
}

.grid_col_4 {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
}

.grid_col_5 {
  justify-self: inline;
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat( auto-fit, minmax(220px, 1fr) );
}

.grid_col_6 {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
}

.statistics_container {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .statistic_container {
    flex-basis: 20%;
    padding: 0 5px;
    min-width: 200px;
  }
}
