@import '../../../../sass_modules/all.scss';
@import '../../../../index.scss';

*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
}

h5 {
    margin: 0;
    padding: 0;
    display: block;
}

.stages {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.stages__stage {
    width: 30%;
}

.columns {
    display: flex;
    margin-top: 22px;
}

.column {
    width: 50%;
}

.column__box {
    border: 1px solid #c4cdda;
    padding: 30px 10px;
    margin-bottom: 20px;
}

.column__mr20 {
    margin-right: 20px;
}

.row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__2col {
        &>div {
            width: 50%;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    &__1col {
        &>div {
            width: 100%;
        }
    }

}

.addButton {
    width: 133px;
    border: 1px solid $green_01;
    background: transparent;
    color: $green_01;
    transition: all 0.3s ease-in-out;
    border-radius: 0px;

    &:hover,
    &:focus {
        background: $green_01;
        color: $white;
        border-color: $green_01 !important;
    }

    &__disabled {
        cursor: not-allowed;
        background: #ccc;
    }
}