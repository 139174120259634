@import "../../../sass_modules/all.scss";

.formFooter {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;

    .btn {
        box-shadow: none;
        transition: opacity 0.3s ease-in-out;
        color: #e6e6e6;
        border-radius: 0px 0px 0px 0px;
        outline: none;
        border: 1px solid $green_01;
        
        &:focus,
        &:hover {
            opacity: 0.8;
        }
    }
    
    .confirmBtn {
        @extend .btn;
        background: $green_01;
        color: #e6e6e6;
    }

    .cancelBtn {
        @extend .btn;
        background-color: white;
        color: $green_01;
    }
}