.detailsBox {
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;

  .grid_col_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
  }
}
