.submitButton {
  width: 100%;
  height: 36px;
  background: #22b74b;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  border-radius: 0px;
  margin: 20px auto auto 0px;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      opacity: 0.3;
      background: #22b74b;
      color: #ffffff;
      border-color: #22b74b;
    }
  }

  &:hover {
    opacity: 0.9;
    background: #22b74b;
    color: #ffffff;
    border-color: #22b74b;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.h3 {
  text-align: center;
  font-weight: bold;
  color: #004d00;
  font-size: 16px;
}
