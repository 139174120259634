h2 {
  margin-bottom: 0;
}

.grid_col_4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
}

.grid_col_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;
}

.grid_col_3_switch {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5px;

  label {
    text-align: center;
  }
}

.grid_col_4_switch {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;

  label {
    text-align: center;
  }
}

.label {
  text-align: center;
}

.grid_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;

  .grid_col_2 {
    row-gap: 20px;
    column-gap: 5px;
  }
}

.grid_with_sku_button {
  display: grid;
  grid-template-columns: auto 32px;
}

.switchGrid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.updateCampaignsBtn {
  background: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  height: 32px;
  width: 100%;
  transition: border 0.3s linear;
  outline: none;
  cursor: pointer;

  &:focus {
    border-color: #40a9ff;
  }

  &:hover {
    border-color: #40a9ff;
  }
}

.sectionDivider {
  width: 100%;
  height: 1px;
  background-color: #40a9ff;
  margin: 25px auto;
}

.border {
  border: 1px solid #40a9ff;
  padding: 10px;
}

.skuInputContainer {
  input {
    background: none !important;
    border: none !important;
    color: red !important;
    font-weight: bold !important;
  }
}

.updateCampaignsButton {
  width: 100%;
}

.radioButton {
  label {
    text-align: center;
  }
}
