@import "../../../../../../sass_modules/colors.scss";

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;

  .counterLabel {
    color: $text;
    margin-bottom: 15px;
  }

  .counter {
    color: $grey_02;
  }
}

.urlGeneratorContainer {
  margin-top: 50px;

  .urlGenerator {
    color: $text;
    margin-bottom: 15px;
  }

  .campaignNameContainer {
    display: flex;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .campaignName {
    color: $grey_02;
    display: block;
    margin-bottom: 10px;
  }

  .input {
    outline: none;
    background: $white;
    border: 1px solid $grey_01;
    height: 36px;
    padding-left: 10px;
    margin-right: 20px;
    min-width: 250px;

    &:focus {
      border: 1px solid $green_01;
    }
  }

  .submit {
    border: none;
    box-shadow: none;
    outline: none;
    height: 36px;
    background: $green_01;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    font-family: "Lato", "Arial";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    min-width: 150px;

    @media (max-width: 480px) {
      margin-top: 15px;
      align-self: flex-start;
    }

    &:hover,
    &:focus {
      opacity: 0.9;
    }
  }
}

.errors {
  width: 100%;
  text-align: center;
  color: red;
  margin: 15px auto;
}
