.productCard {
  min-width: 320px;
  color: #959595;
  font-family: "Lato";
  margin-right: 25px;

  @media (max-width: 1200px) {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .shortDesc {
    font-size: 14px;
    line-height: 17px;
    font-weight: 300;
    margin: 0 0 5px;
  }

  .heading {
    font-size: 18px;
    line-height: 25px;
    font-family: "Open Sans", "Arial";
    font-weight: 600;
    margin: 0 0 20px;
  }

  .admin_table_row {
    background-color: #ff000021;
    border: 1px solid #ff000071;
  }

  &__header {
    img {
      width: 100%;
    }
  }

  &__body {
    background: #fff;
    padding: 20px 45px 30px;
  }

  &__table {
    width: 100%;

    &__row {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      padding-bottom: 4px;
      border-bottom: 1px dashed rgba(#101724, 0.2);
      margin-bottom: 19px;

      &:last-child {
        margin-bottom: 0;
      }

      &__label,
      &__value {
        padding: 0;
        margin: 0;
        font-size: 16px;
        line-height: 19px;
      }

      &__label {
        font-weight: 300;
      }

      &__value {
        font-weight: 400;
        justify-self: end;
        color: #101724;
        text-align: right;

        &__icons {
          display: flex;
          justify-content: flex-end;
          img {
            margin: 0 5px;
          }
        }
      }
    }
  }
}
