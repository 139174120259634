@import "../../../../../sass_modules/all.scss";

.tool {
  background: $white;
  box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
  padding: 30px;

  .header {
    color: $text;
    margin: 0;
    margin-bottom: 15px;
  }

  .para {
    color: $grey_03;
    margin: 0;
    margin-bottom: 20px;
  }

  .button {
    display: block;
    margin: 0;
    color: $green_01;
  }
}
