@import "../../../../sass_modules/all.scss";

.grid {
  width: 100%;
  height: 100%;
  padding: 13px 0px;
  display: flex;
  justify-content: space-between;
  color: #101724;

  @media (max-width: 1100px) {
    justify-content: flex-start;
  }

  .boxContainer {
    display: flex;
    height: 100%;

    .box {
      height: 100%;
      background: #fff;
      box-shadow: 0 0 2px rgba(13, 34, 66, 0.15);
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1100px) {
        display: none;
      }

      a {
        white-space: nowrap;
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        padding: 0 15px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
      }

      p {
        margin: 0;
        padding: 0px 10px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;

        span {
          font-weight: 700;
        }

        .balance {
          color: #22b74b;
        }
      }

      &__lang {
        width: 82px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1100px) {
          display: flex;
        }
      }
    }

    .boxWidth {
      min-width: 133px;
    }
  }

  .userContainer {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .line {
      width: 1px;
      height: 40px;
      background: rgba(#959595, 0.2);
    }

    .userBox {
      width: 205px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;

      button {
        width: 100%;
        padding-left: 35px;
        line-height: 36px;
        height: 36px;
        border: none;
        background: transparent;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        &:focus {
          outline: none;
        }

        .after {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .userBox__profile {
      margin-bottom: 22px;
    }

    .managerBox {
      width: 206px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .contactButton {
    font-size: 20px;
    width: 42px;
    height: 40px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 1);
    color: white;
    opacity: 0.4;
    margin-right: 20px;

    &:hover {
      opacity: 0.64;
      border-color: initial;
    }

    &:focus {
      border-color: initial;
    }
  }
}

.popOverHoverBtn {
  border: none;
  display: flex;
  align-items: center;
  height: 36px;
  width: 150px;
  background: transparent;

  &:focus {
    outline: $green_01;
    outline-offset: 2px;
  }
}
