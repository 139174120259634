.cartItem {
    display: flex;
    padding-top: 15px;
}

.cartItem__leftCol {
    width: 85%;
    margin-right: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #c4cdda;
}

.cartItem__rightCol {
    width: 15%;
}

.cartItem__nameRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    p {
        margin: 0;
        font-weight: bold;
        color: black;
    }
}

.cartItem__discountRow {
    display: flex;
    justify-content: space-between;

    p {
        margin: 0;
    }

    p:first-child {
        color: #6a6c70;
    }

    p:last-child {
        color: #24a88c;
    }
}


.deleteBtn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #eb5757;
    border: none;
    box-shadow: none;
    transition: opacity .2s linear;

    position: relative;
    cursor: pointer;
    outline: none;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 2px;
        background: white;
    }

    &:hover {
        opacity: .9;
    }
}

