.langPickerGrid {
    display: flex;
    align-items: center;
    background: transparent;

    p {
        margin: 0;
        margin-left: 10px;
        margin-right: 5px;
    }
}
