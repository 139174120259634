@import "../../../../../../sass_modules/all.scss";

.container {

  .progressBar {
    width: 80%;
  }

  .resultContainer {
    padding-top: 20px;
    display: flex;
    justify-content: space-evenly;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .button {
      margin-left: 0.5vw;
      background: transparent;
      transition: all 0.3s ease-in-out;
      border-radius: 0px;

      &:hover,
      &:focus {
        color: $white;
      }

      &__disabled {
        cursor: not-allowed;
        background: #ccc;
      }
    }

    .confirmButton {
      @extend .button;
      color: $green_01;
      border: 1px solid $green_01;

      &:hover,
      &:focus {
        background: $green_01;
      }
    }

    .cancelButton {
      @extend .button;
      color: red;
      border: 1px solid red;;

      &:hover,
      &:focus {
        background: red;
      }
    }
  }
}
