.title {
  font-size: 26px;
  margin-bottom: 22px;
  font-weight: bold;
  vertical-align: text-bottom middle;
}

.subTitle {
  margin-left: 20px;
  color: green;
}

.pageInf {
  margin-bottom: 8px;
}
