.label {
    display: block;
    text-align: left;
    margin-bottom: 20px;
    color: rgba(0,0,0,.65);
    width: 100%;
}

.label__discount {
    margin: 25px auto 20px;
}

.pricingsList {
    margin: 25px auto;
}