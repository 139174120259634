.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 5px 5px;
  /* background-color: gray; */
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 1px 1px;
}

.ant-table {
  line-height: 2;
}

.ant-table-body {
  margin: 0 !important;
}

/* .ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before{
    background: rgba(0, 0, 0, 0.15);
} */

.ant-table {
  background-color: white;
}

.ant-table-thead {
  background-color: #d8d3d3;
}

.alert {
  background-color: #ffebe6;
  font-weight: bold;
  color: red;
}

.expandIcon {
  cursor: pointer;
}

.actionsContainer {
  padding: 10px;
}

.actionSpan {
  padding-right: 10px;
  font-size: 15px;
  font-weight: bold;
}

.actionsSelect {
  width: 250px;
}

.actionsConfirmBtn {
  margin-left: 10px;
}