.grid_col_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}

.userName{
    text-align: center;
    font-weight: bold;
    color: #004d00;
    font-size: 16px;
}