$errorColor: #f5222d;
$tooltipErrorColor: #db002f;

.default-required {
  color: $errorColor !important;
}

.error-input {
  border-color: $errorColor !important;
  color: $errorColor !important;

  input {
    border: $errorColor solid 1px;
    color: $errorColor !important;
  }

  .ant-select-selection--single {
    border: $errorColor solid 1px;
    color: $errorColor !important;
  }
}

.default-error-tooltip .ant-tooltip-inner {
  background-color: $tooltipErrorColor !important;
}

.default-error-tooltip .ant-tooltip-arrow {
  border-bottom-color: $tooltipErrorColor !important;
}
