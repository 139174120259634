.latestNews {
    &__button {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
        height: 50px;
        width: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #22b74b;
        color: #22b74b;
        font-size: 16px;
        line-height: 19px;
        transition: opacity .3s ease-in-out;

        &:hover {
            background-color: #22b74b;
            color: white;
            opacity: .8;
        }
    }
}

.newsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}