.list {
    width: 100%;
    height: 260px;
    border: 1px solid #898C90;

    // hide scroll bars in all browsers
    overflow: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    margin-bottom: 31px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #fff; 
  border-top: 4px solid #898C90;; 
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}