.productCard {
  width: 286px;
  max-width: 100%;
  background: #fff;
  padding: 0 30px 26px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .admin_table_row {
    background-color: #ff000021;
    border: 1px solid #ff000071;
  }

  &__title {
    font-family: "Open Sans";
    font-size: 18px;
    line-height: 25px;
    color: black;
    text-align: center;
    margin-bottom: 20px;
    height: 20px;
  }

  &__img {
    margin-bottom: 20px;
    max-width: 286px;
    max-height: 150px;
    min-height: 150px;
  }

  &__table {
    width: 100%;
    margin: 0 0 20px;

    &__row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 2px;
      border-bottom: 1px dashed rgba(16, 23, 36, 0.1);
      margin-bottom: 19px;

      &__label,
      &__value {
        padding: 0;
        margin: 0;
        color: #7c7c7c;
        font-size: 14px;
        line-height: 17px;
      }

      &__label {
        font-weight: 300;
      }

      &__value {
        font-weight: 400;
        justify-self: end;
        color: #101724;
      }
    }
  }

  &__button {
    height: 50px;
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #22b74b;
    color: #22b74b;
    font-size: 16px;
    line-height: 19px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #22b74b;
      color: white;
    }
  }

  &__downloadButton {
    height: 50px !important;
    width: 100% !important;
    border: 2px solid transparent;
    font-size: 16px;
    line-height: 19px;

    span {
      color: white;
    }

    div {
      height: 20px;
    }

    span i {
      background-color: white !important;
    }

    &:hover {
      color: white;
    }

    &:visited {
      color: white;
    }
  }

  &__downloadButtonLoading {
    pointer-events: none;
  }
}
