@import '../../../../../../sass_modules/all.scss';

.container {
    display: flex;
    margin-left: 8%;
    margin-top: 10px;

    .arrow {
        color: $green_01;
        font-size: 30px;
        display: flex;
        align-items: center;
    }

    .status {
        border: 1px solid $green_01;
        margin: 0 0.3%;
        min-width: 140px;
        border-radius: 3px;
        cursor: pointer;

        .commonText {
            text-align: center;
            padding: 3px;
            border-top: 1px solid $green_01;
        }

        .label {
            @extend .commonText;
            background-color: $green_01;
            color: #ffffff;
            padding: 5px;
        }
    }

    .prevStatus {
        @extend .status;
        background-color: #00000010;

        .label {
            background-color: $green_01;
            opacity: 0.7;
        }

    }

    .currStatus {
        @extend .status;
    }

    .nextStatus {
        @extend .status;

        .label {
            background-color: $green_01;
            opacity: 0.85;
        }
    }
}