@import "../../../../../sass_modules/all.scss";

.container {
  width: 100%;
  margin: 20px 0 0 0;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
    }

    .addButton {
      width: 133px;
      height: 34px;
      border: 1px solid $green_01;
      background: transparent;
      color: $green_01;
      transition: all 0.3s ease-in-out;
      border-radius: 0px 0px 0px 0px;

      &:hover,
      &:focus {
        background: $green_01;
        color: $white;
      }
    }
  }

  .inputs {
    width: 100%;
    .formItem {
      display: flex;
      margin-bottom: 20px;
      
      input {
        height: 36px;
        width: 100%;
        background: $white;
        border: 1px solid $grey_01;
        box-shadow: none;
        outline: none;
        padding-left: 12px;
        border-radius: 0px 0px 0px 0px;

        &:focus,
        &:hover {
          border: 1px solid $green_01;
        }
      }

      .controlButton {
        height: 36px;
        width: 36px;
        background: $green_01;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: opacity 0.3s ease-in-out;
        outline: none;
        color: #e6e6e6;
        border-radius: 0px 0px 0px 0px;

        &:focus,
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .submitBtn {
    width: 133px;
    height: 36px;
    border: 1px solid $grey_01;
    color: $white;
    font-family: "Lato", "Arial";
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
  }

  .gray {
    background: $grey_01;
  }

  .green {
    background: $green_01;
  }
}
