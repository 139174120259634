@import '../../../../../sass_modules/fonts';
@import '../../../../../sass_modules/colors';

.grid {
    display: grid;
    grid-template-columns: 36px auto;
    grid-template-areas:
        'img text';
    align-items: center;
    grid-column-gap: 10px;
    height: 100%;
    width: 100%;
    cursor: pointer;

    .img {
        grid-area: img;
        position: relative;
        height: 36px;
        display: flex;
        align-items: center;

        img {
            width: 36px;
            height: 36px;
            max-width: 100%;
            border-radius: 50%;
        }

        .statusDot {
            border: 2px solid $bg_white;
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: $green_01;
        }
    }

    .text {
        height: 36px;
        width: 100%;
        grid-area: text;

        p,
        h6 {
            text-align: left;
            margin: 0;
        }

        p {
            font-size: 14px;
            color: $grey_02;
            margin-bottom: 2px;
        }

        h6 {
            color: $text;
        }


    }
}