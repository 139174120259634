.h1 {
    font-family: 'Open Sans', 'Arial' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 30px !important;
    line-height: 36px !important;

    @media(max-width: 767px) {
        font-size: 24px !important;
        line-height: 32px !important;
    }
}

.h2 {
    font-family: 'Open Sans', 'Arial' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 26px !important;
    line-height: 30px !important;

    @media(max-width: 767px) {
        font-size: 20px !important;
        line-height: 30px !important;
    }
}

.h3 {
    font-family: 'Open Sans', 'Arial' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;

    @media(max-width: 767px) {
        font-size: 16px !important;
        line-height: 22px !important;
    }
}

.h4 {
    font-family: 'Nunito Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 22px !important;

    &__semi {
        @extend .h4;
        font-weight: 400 !important;
        line-height: 19px !important;
    }

    &__bold {
        @extend .h4;
        font-weight: 600 !important;
        line-height: 19px !important;
    }
}

.h5 {
    font-family: 'Nunito Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 15px !important;
    line-height: 18px !important;

    &__text {
        @extend .h5;
        line-height: 20px !important;
    }

    &__semi {
        @extend .h5;
        font-weight: 400 !important;
        line-height: 18px !important;
    }

    &__bold {
        @extend .h5;
        font-weight: 600 !important;
        line-height: 18px !important;
    }
}

.h6 {
    font-family: 'Nunito Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    &__text {
        @extend .h6;
        line-height: 20px !important;
    }

    &__bold {
        @extend .h6;
        font-weight: 400 !important;
    }

    &__extrabold {
        @extend .h6;
        font-weight: 600 !important;
    }

    &__semibold {
        @extend .h6;
        font-weight: 600!important;
    }
}

.table__reg {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    ;
}

.chart {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
}