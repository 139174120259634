.filters {
    width: 100%;
    margin-bottom: 20px;
}

.successModal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto;
    text-align: center;
    font-weight: bold;
}