.becomeAPartnerBtn {
    display: block;
    // margin: 30px auto 80px;
    margin: 0 auto;
    padding: 0;
    color: #fff;
    font-size: 16px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 19px;
    width: 230px;
    height: 50px;
    background: #22b74b;
    transition: opacity .3s ease-in-out;

    @media(max-width: 767px) {
        // margin: 30px auto 108px;
    }

    &:hover {
        color: #fff;
        opacity: .8;
    }
}