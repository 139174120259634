.classifierName {
  text-align: center;
  font-weight: bold;
  color: #004d00;
  font-size: 16px;
}

.addToAffbayClassifiersCheckbox {
  margin: 10px 0px;
}

.center {
  font-size: 16px;
  text-align: center;
}

.saveButton {
  margin-top: 25px;
  width: 100%;
}
