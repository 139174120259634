@import '../../../../../../../../sass_modules/all.scss';

.grid {
    // width: 235px;
    background: $white;
    box-shadow: 0px 0px 2px rgba(13, 34, 66, 0.15);
    margin-bottom: 10px;
    padding: 15px;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 20px auto;
    align-items: center;

    p {
        margin: 0;
    }

    .landingName {
        color: $text;
    }

    .icons {
        float: right;
        display: flex;
        padding: 10px 0 0 0;

        .status {
            width: 31px;
            height: 20px;
            background: #fbbc06;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $text;
        }
    }

}
