.headerStyle {
  background-color: #f8fafc;
  margin-left: 20px;
  /* width: 1018px; */
}

.labelStyle1 {
  width: 133px;
  padding: 12px 20px 11px 20px;
  background-color: #fff;
}
