
.radioStyle {
	display: 'block';
	height: '30px';
	line-height: '30px';
  };

  .radioGrid {
	  width: 100%;
	  display: flex;
	  justify-content: space-around;
	  align-items: center;

	}

.flexDirectionColumn {
	flex-direction: column;  
}