.btn {
    height: 36px;
    width: 184px;
    border: 1px solid #22b74b;
    border-radius: 1px;
    color: #22b74b;
    background-color: white;
    font-size: 14px;
    line-height: 18px;
    transition: opacity .3s ease-in-out;

    &:hover {
        background-color: #22b74b;
        color: white;
        opacity: .8;
        border: 1px solid #22b74b;
    }
}