.statistic {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1350px) {
    margin-bottom: 0;

    &:last-child {
      margin-left: 30px;
    }
  }

  &__title {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;

    margin-bottom: 0;
  }

  &__value {
    font-size: 16px;
    font-weight: 700;

    margin-bottom: 0;
  }
}
