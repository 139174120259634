@import "../../../../../sass_modules/all.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.grid {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 337px;
  width: 100%;

  .nomargin {
    margin-bottom: 0 !important;
  }

  .formItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    &__input,
    &__textarea {
      width: 100%;
      outline: none;
      box-shadow: none;
      border: 1px solid $grey_02;
      height: 36px;

      &:focus {
        border: 1px solid rgba(34, 183, 72, 1);
      }
    }

    &__label {
      text-align: left;
      width: 100%;
      color: $grey_02;
      margin-bottom: 10px;
    }

    &__input {
      padding-left: 15px;

      &__disabled {
        background: #ededed;
        display: flex;
        align-items: center;
      }
    }

    &__submit {
      width: 100%;
      height: 36px;
      background: $green_01;
      color: $white;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.9;
        background: $green_02;
        font-size: 105%;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &__reset {
      width: 100%;
      height: 36px;
      background: transparent;
      color: $green_01;
      font-size: 14px;
      line-height: 18px;
      border: 1px solid $green_01;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 0.9;
        font-size: 105%;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .formFooter {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 184px 133px;
    grid-column-gap: 20px;
  }

  button:disabled,
  button[disabled] {
    cursor: not-allowed;
  }
}
