@import '../../../../sass_modules/all.scss';

.container {
    max-width: 100%;
}

.label {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.input {
    width: 100%;
    border: 1px solid #898C90;
    box-shadow: none;
    padding: 5px;
    height: 36px;

    &:focus {
        outline: none;
        border:  1px solid $green_01;
    }
}

.buttons {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.p {
    margin: 10px;
}